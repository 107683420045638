/* eslint-disable no-unused-vars */
import { Button, Space, Modal, Typography, Steps } from "antd";
import React, { useState } from "react";
import { DeleteOutlined, QrcodeOutlined, ReloadOutlined } from "@ant-design/icons";
import { MdPhonelinkErase } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { useCheckCoupleState, useResetCoupleKey } from "../../../services";
import { primaryColor } from "../../layout";
import { CoupleCode } from "../../others";

const { Title, Paragraph } = Typography;

// eslint-disable-next-line no-shadow
enum StepsEnum {
  INFO = 0,
  UNINSTALL = 1,
  UNCOUPLE = 2,
  SCAN_QR = 3,
}
const getSteps = (t: TFunction) => [
  {
    key: StepsEnum.INFO,
    preContent: t("participant.changeSmartphone.info.preContent"),
    nextButtonText: t("common.next"),
  },
  {
    key: StepsEnum.UNINSTALL,
    title: t("participant.changeSmartphone.uninstall.title"),
    preContent: t("participant.changeSmartphone.steps.preContent"),
    content: t("participant.changeSmartphone.uninstall.content"),
    nextButtonText: t("common.next"),
    stepIcon: <DeleteOutlined />,
  },
  {
    key: StepsEnum.UNCOUPLE,
    title: t("participant.changeSmartphone.uncouple.title"),
    preContent: t("participant.changeSmartphone.steps.preContent"),
    content: t("participant.changeSmartphone.uncouple.content"),
    nextButtonText: t("participant.actions.menu.uncouple"),
    buttonIcon: <MdPhonelinkErase />,
    stepIcon: <MdPhonelinkErase />,
  },
  {
    key: StepsEnum.SCAN_QR,
    title: t("participant.changeSmartphone.scanQrCode.title"),
    preContent: t("participant.changeSmartphone.steps.preContent"),
    content: t("participant.changeSmartphone.scanQrCode.content"),
    nextButtonText: t("participant.actions.qrCode.confirm.okButton"),
    buttonIcon: <ReloadOutlined />,
    stepIcon: <QrcodeOutlined />,
  },
];

/**
 * Component to guide the user through the process of changing the smartphone.
 * The component is expected to be used inside an antd Modal.
 */
export function ChangeSmartphone({ participantId }: { participantId: string }) {
  const [currentStep, setCurrentStep] = useState(StepsEnum.INFO);
  const { checkCoupleState } = useCheckCoupleState(participantId);
  const { resetCoupleKey } = useResetCoupleKey({});
  const [coupleUrl, setCoupleUrl] = useState<any>(null);
  const { t } = useTranslation();
  const steps = getSteps(t);

  const handleNextStep = async () => {
    switch (currentStep) {
      case StepsEnum.INFO:
        setCurrentStep(StepsEnum.UNINSTALL);
        break;
      case StepsEnum.UNINSTALL:
        setCurrentStep(StepsEnum.UNCOUPLE);
        break;
      case StepsEnum.UNCOUPLE:
        resetCoupleKey(participantId).then((data) => {
          setCoupleUrl(data?.coupleUrl);
          setCurrentStep(StepsEnum.SCAN_QR);
        });
        break;
      case StepsEnum.SCAN_QR:
        checkCoupleState().then((isCoupled) => {
          if (isCoupled) {
            Modal.destroyAll();
          }
        });
        break;
      default:
    }
  };

  return (
    <div>
      <Paragraph>{steps[currentStep].preContent}</Paragraph>
      {currentStep !== StepsEnum.INFO && (
        <div
          style={{
            borderColor: `${primaryColor}60`,
            padding: 16,
            borderStyle: "none",
            borderWidth: 1,
            borderRadius: 8,
            backgroundColor: "#fafafa",
          }}
        >
          <Steps
            style={{ paddingLeft: 16, paddingRight: 16 }}
            current={currentStep - 1}
            items={steps
              .slice(StepsEnum.UNINSTALL, StepsEnum.SCAN_QR + 1)
              .map((step) => ({ icon: step.stepIcon }))}
          />

          <div
            style={{
              marginTop: 16,
            }}
          >
            <Title level={5} style={{ color: "black", marginTop: 0 }}>
              {steps[currentStep].title}
            </Title>
            <Paragraph>{steps[currentStep].content}</Paragraph>
            {currentStep === StepsEnum.SCAN_QR && (
              <CoupleCode
                value={coupleUrl}
                size={200}
                style={{
                  borderStyle: "none",
                  margin: "auto",
                  marginTop: 16,
                  marginBottom: 16,
                }}
              />
            )}
          </div>
        </div>
      )}
      <Space style={{ float: "right", marginTop: 16 }}>
        <Button onClick={() => Modal.destroyAll()}>
          {currentStep === StepsEnum.SCAN_QR ? t("common.close") : t("common.cancel")}
        </Button>
        <Button
          icon={steps[currentStep].buttonIcon}
          type="primary"
          onClick={() => handleNextStep()}
        >
          {steps[currentStep].nextButtonText}
        </Button>
      </Space>
    </div>
  );
}

export default ChangeSmartphone;
