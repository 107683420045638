/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */

import React from "react";
import dayjs from "dayjs";
import {
  CalendarOutlined,
  CaretRightFilled,
  CheckCircleFilled,
  ClockCircleFilled,
  DeleteOutlined,
  ExclamationCircleFilled,
  ExclamationCircleOutlined,
  PauseCircleFilled,
  PauseOutlined,
  QrcodeOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
  SettingOutlined,
  SoundOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { MdPhonelinkErase } from "react-icons/md";
import { App, Result, Space, Typography } from "antd";
import {
  ActionEnum,
  useCheckCoupleState,
  useDeleteParticipant,
  useExecutorStatistics,
  usePermission,
  useResetCoupleKey,
  useUpdateParticipant,
  useUpdateTherapyPeriod,
} from "../../services";
import { momentToStringWithoutTimezone } from "../../utils";
import {
  errorRedPrimary,
  informationBluePrimary,
  successGreenPrimary,
  warningYellowPrimary,
} from "../layout";
import { ChangeSmartphone, SendTestNotification } from "./components";
import { CenterInModal, CoupleCode } from "../others";

const { Text } = Typography;

interface IStateButtonStyle {
  backgroundColor: string;
  textColor: string;
  borderColor: string;
  tagColor: string;
}

const uncoupledStyle: IStateButtonStyle = {
  backgroundColor: "#fff2e8",
  textColor: "#d4380d",
  borderColor: "#ffbb96",
  tagColor: "volcano",
};

const runningStyle: IStateButtonStyle = {
  backgroundColor: "#f6ffed",
  textColor: "#389e0d",
  borderColor: "#b7eb8f",
  tagColor: "green",
};

const pausedStyle: IStateButtonStyle = {
  backgroundColor: "#fffbe6",
  textColor: "#d48806",
  borderColor: "#ffe58f",
  tagColor: "gold",
};

const waitingStyle: IStateButtonStyle = {
  backgroundColor: "#e6f7ff",
  textColor: "#096dd9",
  borderColor: "#91d5ff",
  tagColor: "blue",
};

const defaultStyle: IStateButtonStyle = {
  backgroundColor: "#fafafa",
  textColor: "#8c8c8c",
  borderColor: "#fafafa",
  tagColor: "grey",
};

export interface IGetStateProps {
  coupleUrl?: string | null;
  therapyStart?: string | null;
  therapyEnd?: string | null;
}

interface IGetStateDescriptionProps {
  state: ParticipantStateEnum;
  therapyStart?: string | null;
  therapyEnd?: string | null;
  t: TFunction;
}

// eslint-disable-next-line no-shadow
export enum ParticipantStateEnum {
  UNCOUPLED = "Uncoupled",
  WAITING = "Waiting",
  PAUSED = "Paused",
  RUNNING = "Running",
}

/**
 * Returns the name of the participant state based on the given props
 * (coupleUrl, therapyStart, therapyEnd)
 */
export const getStateName = ({ coupleUrl, therapyStart, therapyEnd }: IGetStateProps) => {
  if (coupleUrl !== null) {
    return ParticipantStateEnum.UNCOUPLED;
  }
  if (therapyStart !== null && dayjs(therapyStart).isAfter(dayjs())) {
    return ParticipantStateEnum.WAITING;
  }
  if (therapyEnd !== null && dayjs(therapyEnd).isBefore(dayjs())) {
    return ParticipantStateEnum.PAUSED;
  }
  return ParticipantStateEnum.RUNNING;
};

/**
 * Returns the icon for the given participant state
 */
export const StateIcon = (props: IGetStateProps) => {
  const state = getStateName(props);

  const fontSize = 20;
  const icon = () => {
    switch (state) {
      case ParticipantStateEnum.UNCOUPLED:
        return (
          <ExclamationCircleFilled
            data-id="participant.state.icon.uncoupled"
            style={{
              color: errorRedPrimary,
              fontSize,
            }}
          />
        );
      case ParticipantStateEnum.PAUSED:
        return (
          <PauseCircleFilled
            data-id="participant.state.icon.paused"
            style={{
              color: warningYellowPrimary,
              fontSize,
            }}
          />
        );
      case ParticipantStateEnum.RUNNING:
        return (
          <CheckCircleFilled
            data-id="participant.state.icon.running"
            style={{
              color: successGreenPrimary,
              fontSize,
            }}
          />
        );
      case ParticipantStateEnum.WAITING:
        return (
          <ClockCircleFilled
            data-id="participant.state.icon.waiting"
            style={{
              color: informationBluePrimary,
              fontSize,
            }}
          />
        );
      default:
        return null;
    }
  };

  return icon();
};

/**
 * Renders the participant state with text and icon
 */
export function StateWithIcon(props: IGetStateProps) {
  const state = getStateName(props);

  return (
    <Space>
      <StateIcon {...props} />
      <Text data-id="participant.coupleStatus">{state}</Text>
    </Space>
  );
}

/**
 * Returns the style for the given participant state
 */
const getStyle = (state: string) => {
  switch (state) {
    case ParticipantStateEnum.UNCOUPLED:
      return uncoupledStyle;
    case ParticipantStateEnum.PAUSED:
      return pausedStyle;
    case ParticipantStateEnum.RUNNING:
      return runningStyle;
    case ParticipantStateEnum.WAITING:
      return waitingStyle;
    default:
      return defaultStyle;
  }
};

/**
 * Returns some information based on the actual participant
 */
const getDescription = ({ state, therapyEnd, therapyStart, t }: IGetStateDescriptionProps) => {
  if (therapyStart === null && state === ParticipantStateEnum.UNCOUPLED) {
    if (therapyEnd !== null && dayjs(therapyEnd).isBefore(dayjs())) {
      return t("participant.therapyPeriodStatus.ended");
    }
    return t("participant.therapyPeriodStatus.startsWhenCoupled");
  }

  if (therapyStart !== null && dayjs(therapyStart).isAfter(dayjs())) {
    return t("participant.therapyPeriodStatus.startsAt", {
      startDate: dayjs(therapyStart).toDate().toLocaleDateString(),
    });
  }

  if (therapyEnd !== null) {
    if (dayjs(therapyEnd).isAfter(dayjs())) {
      return t("participant.therapyPeriodStatus.endsAt", {
        endDate: dayjs(therapyEnd).toDate().toLocaleDateString(),
      });
    }
    return t("participant.therapyPeriodStatus.ended");
  }
  return t("participant.therapyPeriodStatus.noEnd");
};

export interface IParticipantStateActionsProps {
  therapyPeriodId: string;
  therapyStart?: string | null;
  therapyEnd?: string | null;
  coupleUrl?: string | null;
  participantId: string;
  pseudonym: string;
  studyId: string;
  state: ParticipantStateEnum;
  coupledAt: string | null;
}

export interface IParticipantsStateAction {
  icon?: React.ReactNode;
  actionGroup: string;
  title: string;
  disabled: boolean;
  availableOnStates: ParticipantStateEnum[];
  availableForPermissions: ActionEnum[];
  tooltip?: string;
  key: ParticipantActionEnum;
  action: () => void;
}

// eslint-disable-next-line no-shadow
export enum ParticipantActionEnum {
  CHANGE_SMARTPHONE,
  CHECK_COUPLE_STATE,
  COUPLE_SMARTPHONE,
  DELETE,
  EDIT_INFO,
  EDIT_PERIOD,
  GO_TO_DASHBOARD,
  PAUSE,
  START,
  UNCOUPLE_SMARTPHONE,
  SEND_TEST_NOTIFICATION,
}

/**
 * Get available actions for a given participant state and a given set of
 * permissions
 */
export const getAvailableActions = ({
  t,
  state,
  permissions,
  isExecutorExpired,
  isParticipantLimitReached,
  wasParticipantCoupledBefore,
  actionShowQrCode,
  actionCheckCoupleState,
  actionStartNow,
  actionStopNow,
  actionEditTherapyPeriod,
  actionChangeSmartphone,
  actionUncoupleSmartphone,
  actionSendTestNotification,
  actionEditPseudonymGroup,
  actionDeleteParticipant,
}: {
  t: TFunction;
  actionShowQrCode: () => void;
  actionCheckCoupleState: () => void;
  actionStartNow: () => void;
  actionStopNow: () => void;
  actionEditTherapyPeriod: () => void;
  actionChangeSmartphone: () => void;
  actionUncoupleSmartphone: () => void;
  actionSendTestNotification: () => void;
  actionEditPseudonymGroup: () => void;
  actionDeleteParticipant: () => void;
  state: ParticipantStateEnum;
  permissions: ActionEnum[];
  isExecutorExpired: boolean;
  isParticipantLimitReached: boolean;
  wasParticipantCoupledBefore: boolean;
}) => {
  const studyExpiredTooltipText = isExecutorExpired
    ? t("participant.actions.studyExpiredTooltip")
    : undefined;

  const allActions: IParticipantsStateAction[] = [
    {
      title: t("participant.actions.menu.showQrCode"),
      icon: <QrcodeOutlined />,
      action: actionShowQrCode,
      disabled: isExecutorExpired || (isParticipantLimitReached && !wasParticipantCoupledBefore),
      availableOnStates: [ParticipantStateEnum.UNCOUPLED],
      availableForPermissions: [
        ActionEnum.PARTICIPANT_EDIT_THERAPY_CONFIG,
        ActionEnum.PARTICIPANT_EDIT_SETTINGS,
      ],
      actionGroup: "smartphone",
      tooltip:
        isExecutorExpired || (isParticipantLimitReached && !wasParticipantCoupledBefore)
          ? t("participant.actions.studyExpiredOrLimitReachedTooltip")
          : undefined,
      key: ParticipantActionEnum.COUPLE_SMARTPHONE,
    },
    {
      title: t("participant.actions.menu.recheckCoupling"),
      icon: <ReloadOutlined />,
      action: actionCheckCoupleState,
      disabled: isExecutorExpired,
      tooltip: studyExpiredTooltipText,
      availableOnStates: [ParticipantStateEnum.UNCOUPLED],
      availableForPermissions: [
        ActionEnum.PARTICIPANT_EDIT_THERAPY_CONFIG,
        ActionEnum.PARTICIPANT_EDIT_SETTINGS,
      ],
      actionGroup: "smartphone",
      key: ParticipantActionEnum.CHECK_COUPLE_STATE,
    },
    {
      title: t("participant.actions.menu.startNow"),
      icon: <CaretRightFilled />,
      action: actionStartNow,
      disabled: isExecutorExpired,
      tooltip: studyExpiredTooltipText,
      availableOnStates: [ParticipantStateEnum.PAUSED, ParticipantStateEnum.WAITING],
      availableForPermissions: [
        ActionEnum.PARTICIPANT_EDIT_THERAPY_CONFIG,
        ActionEnum.PARTICIPANT_EDIT_SETTINGS,
      ],
      actionGroup: "therapy",
      key: ParticipantActionEnum.START,
    },
    {
      title: t("participant.actions.menu.pauseNow"),
      icon: <PauseOutlined />,
      action: actionStopNow,
      disabled: false,
      availableOnStates: [ParticipantStateEnum.RUNNING],
      availableForPermissions: [
        ActionEnum.PARTICIPANT_EDIT_THERAPY_CONFIG,
        ActionEnum.PARTICIPANT_EDIT_SETTINGS,
      ],
      actionGroup: "therapy",
      key: ParticipantActionEnum.PAUSE,
    },
    {
      title: t("participant.actions.menu.editPeriod"),
      icon: <CalendarOutlined />,
      action: actionEditTherapyPeriod,
      actionGroup: "therapy",
      disabled: isExecutorExpired,
      tooltip: studyExpiredTooltipText,
      availableOnStates: [
        ParticipantStateEnum.PAUSED,
        ParticipantStateEnum.RUNNING,
        ParticipantStateEnum.WAITING,
        ParticipantStateEnum.UNCOUPLED,
      ],
      availableForPermissions: [
        ActionEnum.PARTICIPANT_EDIT_THERAPY_CONFIG,
        ActionEnum.PARTICIPANT_EDIT_SETTINGS,
      ],
      key: ParticipantActionEnum.EDIT_PERIOD,
    },
    {
      title: t("participant.actions.menu.changeSmartphone"),
      icon: <SwapOutlined />,
      action: actionChangeSmartphone,
      disabled: isExecutorExpired,
      tooltip: studyExpiredTooltipText,
      availableOnStates: [
        ParticipantStateEnum.PAUSED,
        ParticipantStateEnum.RUNNING,
        ParticipantStateEnum.WAITING,
      ],
      availableForPermissions: [
        ActionEnum.PARTICIPANT_EDIT_THERAPY_CONFIG,
        ActionEnum.PARTICIPANT_EDIT_SETTINGS,
      ],
      actionGroup: "smartphone",
      key: ParticipantActionEnum.CHANGE_SMARTPHONE,
    },
    {
      title: t("participant.actions.menu.uncouple"),
      icon: <MdPhonelinkErase style={{ marginRight: 6, padding: 0 }} />,
      action: actionUncoupleSmartphone,
      disabled: false,
      availableOnStates: [
        ParticipantStateEnum.PAUSED,
        ParticipantStateEnum.RUNNING,
        ParticipantStateEnum.WAITING,
      ],
      availableForPermissions: [
        ActionEnum.PARTICIPANT_EDIT_THERAPY_CONFIG,
        ActionEnum.PARTICIPANT_EDIT_SETTINGS,
      ],
      actionGroup: "smartphone",
      key: ParticipantActionEnum.UNCOUPLE_SMARTPHONE,
    },
    {
      title: t("participant.actions.menu.sendTestNotification"),
      icon: <SoundOutlined />,
      action: actionSendTestNotification,
      disabled: isExecutorExpired,
      tooltip: studyExpiredTooltipText,
      availableOnStates: [
        ParticipantStateEnum.PAUSED,
        ParticipantStateEnum.RUNNING,
        ParticipantStateEnum.WAITING,
      ],
      availableForPermissions: [ActionEnum.PARTICIPANT_EDIT_SETTINGS],
      actionGroup: "smartphone",
      key: ParticipantActionEnum.SEND_TEST_NOTIFICATION,
    },
    {
      title: t("participant.actions.menu.editGroupPseudonym"),
      icon: <SettingOutlined />,
      action: actionEditPseudonymGroup,
      actionGroup: "settings",
      disabled: false,
      availableOnStates: [
        ParticipantStateEnum.PAUSED,
        ParticipantStateEnum.RUNNING,
        ParticipantStateEnum.WAITING,
        ParticipantStateEnum.UNCOUPLED,
      ],
      availableForPermissions: [ActionEnum.PARTICIPANT_EDIT_SETTINGS],
      key: ParticipantActionEnum.EDIT_INFO,
    },
    {
      title: t("participant.actions.menu.delete"),
      icon: <DeleteOutlined />,
      action: actionDeleteParticipant,
      disabled: false,
      actionGroup: "settings",
      availableOnStates: [
        ParticipantStateEnum.PAUSED,
        ParticipantStateEnum.RUNNING,
        ParticipantStateEnum.WAITING,
        ParticipantStateEnum.UNCOUPLED,
      ],
      availableForPermissions: [ActionEnum.PARTICIPANT_DELETE],
      key: ParticipantActionEnum.DELETE,
    },
  ];

  const filteredActionsAccordingToStateAndPermission = allActions
    .filter((action) => action.availableOnStates.includes(state))
    .filter((action) =>
      action.availableForPermissions.some((permission) => permissions.includes(permission)),
    );

  return { actions: filteredActionsAccordingToStateAndPermission };
};

/**
 * WrapperHook to get helper functions needed to handle  the participant state
 * @param param0
 * @returns
 */
const useStateActionsUtils = ({
  studyId,
  participantId,
}: Pick<IParticipantStateActionsProps, "studyId" | "participantId">) => {
  const { deleteParticipant } = useDeleteParticipant({
    studyId,
  });
  const { updateTherapyPeriod, showUpdateTherapyPeriodModal } = useUpdateTherapyPeriod({
    participantId,
  });
  const { checkCoupleState } = useCheckCoupleState(participantId);
  const { resetCoupleKey } = useResetCoupleKey({});
  const { showUpdateParticipantModal } = useUpdateParticipant({ studyId, participantId });
  const permissions: ActionEnum[] = [];

  const { permitted: canDelete } = usePermission({
    action: ActionEnum.PARTICIPANT_DELETE,
    participantId,
  });

  const { permitted: canEditParticipant } = usePermission({
    action: ActionEnum.PARTICIPANT_EDIT_SETTINGS,
    participantId,
  });

  const { permitted: canEditTherapy } = usePermission({
    action: ActionEnum.PARTICIPANT_EDIT_THERAPY_CONFIG,
    participantId,
  });

  if (canDelete && !permissions.includes(ActionEnum.PARTICIPANT_DELETE)) {
    permissions.push(ActionEnum.PARTICIPANT_DELETE);
  }

  if (canEditParticipant && !permissions.includes(ActionEnum.PARTICIPANT_EDIT_SETTINGS)) {
    permissions.push(ActionEnum.PARTICIPANT_EDIT_SETTINGS);
  }

  if (canEditTherapy && !permissions.includes(ActionEnum.PARTICIPANT_EDIT_THERAPY_CONFIG)) {
    permissions.push(ActionEnum.PARTICIPANT_EDIT_THERAPY_CONFIG);
  }

  return {
    deleteParticipant,
    updateTherapyPeriod,
    showUpdateTherapyPeriodModal,
    checkCoupleState,
    resetCoupleKey,
    showUpdateParticipantModal,
    permissions,
  };
};

/**
 * Hook to get available actions for a participant state. Actions can be used to
 * render buttons or menu items.
 * @param param0
 * @returns
 */
export const useParticipantStateActions = ({
  studyId,
  participantId,
  pseudonym,
  therapyStart,
  therapyEnd,
  therapyPeriodId,
  coupleUrl,
  coupledAt,
  state,
}: IParticipantStateActionsProps) => {
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const {
    deleteParticipant,
    resetCoupleKey,
    checkCoupleState,
    permissions,
    showUpdateParticipantModal,
    showUpdateTherapyPeriodModal,
    updateTherapyPeriod,
  } = useStateActionsUtils({ studyId, participantId });
  const {
    usageStatistics,
    loading: executorStatisticsLoading,
    refetch: refetchExecutorStatistics,
  } = useExecutorStatistics();
  const isExecutorExpired = usageStatistics?.isExpired ?? false;
  const isParticipantLimitReached = usageStatistics?.participantLimitReached ?? false;

  const showDeleteConfirm = () => {
    modal.confirm({
      title: t("participant.actions.delete.confirm.title", { pseudonym }),
      icon: <ExclamationCircleOutlined />,
      content: t("participant.actions.delete.confirm.description"),
      okText: t("common.yes"),
      okType: "danger",
      cancelText: t("common.no"),
      onOk() {
        deleteParticipant(participantId);
      },
    });
  };

  const showResetCoupleKeyConfirmation = () => {
    modal.confirm({
      title: t("participant.actions.uncouple.confirm.title", { pseudonym }),
      icon: <ExclamationCircleOutlined />,
      content: t("participant.actions.uncouple.confirm.description"),
      okText: t("common.yes"),
      okType: "danger",
      cancelText: t("common.no"),
      onOk() {
        resetCoupleKey(participantId);
      },
    });
  };

  const showChangeSmartphoneConfirmation = () => {
    modal.confirm({
      title: t("participant.actions.menu.changeSmartphone"),
      icon: null,
      content: (
        <CenterInModal>
          <ChangeSmartphone participantId={participantId} />
        </CenterInModal>
      ),
      footer: null,
      width: 480,
    });
  };

  const showSendTestNotificationConfirmation = () => {
    modal.confirm({
      title: t("participant.actions.menu.sendTestNotification"),
      icon: null,
      content: (
        <CenterInModal>
          <SendTestNotification participantId={participantId} />{" "}
        </CenterInModal>
      ),
      footer: null,
      width: 480,
    });
  };

  const showQrCode = (url?: string | null) => {
    const qrCodeModal = modal.confirm({
      title: t("participant.actions.menu.showQrCode"),
      icon: null,
      style: { paddingLeft: -24 },
      content: (
        <CenterInModal>
          {url == null ? (
            <Result status="success" title={t("participant.actions.qrCode.confirm.success")} />
          ) : (
            <Space
              direction="vertical"
              align="center"
              data-id="coupleUrlContainer"
              data-coupleUrl={url}
            >
              <Text>{t("participant.actions.qrCode.confirm.title")}</Text>
              <CoupleCode value={url} size={200} bordered={false} />
            </Space>
          )}
        </CenterInModal>
      ),

      okText: t("participant.actions.qrCode.confirm.okButton"),
      cancelText: t("common.cancel"),
      okButtonProps: {
        icon: <ReloadOutlined />,
        disabled: url == null,
        onClick: () => {
          checkCoupleState().then((isCoupled) => {
            refetchExecutorStatistics();
            if (isCoupled) {
              qrCodeModal.destroy();
            }
          });
        },
      },
      cancelButtonProps: {
        onClick: () => {
          checkCoupleState();
          refetchExecutorStatistics();
          qrCodeModal.destroy();
        },
      },
      onOk: () => true,
      onCancel: () => true,
    });
  };

  const startTherapyPeriodNow = async () => {
    const start = momentToStringWithoutTimezone(dayjs());
    let end = therapyEnd;

    const isTherapyExpired = dayjs(therapyEnd).isBefore(start);

    if (isTherapyExpired) {
      try {
        const response = await refetchExecutorStatistics();
        const expiresAt = response?.data?.getExecutorStatisticsAction?.expiresAt;
        end = expiresAt ? momentToStringWithoutTimezone(dayjs(expiresAt)) : null;
      } catch (error) {
        console.error("Failed to fetch executor statistics:", error);
      }
    }

    updateTherapyPeriod({
      start,
      end,
      therapyPeriodId,
    });
  };

  const stopTherapyPeriodNow = () => {
    modal.confirm({
      title: t("participant.actions.pause.confirm.title"),
      icon: <QuestionCircleOutlined />,
      content: t("participant.actions.pause.confirm.description", { pseudonym }),
      okText: t("common.yes"),
      cancelText: t("common.no"),
      onOk() {
        const end = momentToStringWithoutTimezone(dayjs());
        const start = dayjs(therapyStart).isAfter(end) ? null : therapyStart;
        updateTherapyPeriod({
          start,
          end,
          therapyPeriodId,
        });
      },
    });
  };

  const availableActions = getAvailableActions({
    t,
    state,
    permissions,
    isExecutorExpired,
    isParticipantLimitReached,
    wasParticipantCoupledBefore: coupledAt !== null,
    actionShowQrCode: () => showQrCode(coupleUrl),
    actionCheckCoupleState: checkCoupleState,
    actionStartNow: startTherapyPeriodNow,
    actionStopNow: stopTherapyPeriodNow,
    actionEditTherapyPeriod: () =>
      showUpdateTherapyPeriodModal({
        therapyPeriodId,
        start: therapyStart,
        end: therapyEnd,
        maxEndDate: usageStatistics?.expiresAt,
      }),
    actionChangeSmartphone: showChangeSmartphoneConfirmation,
    actionUncoupleSmartphone: showResetCoupleKeyConfirmation,
    actionSendTestNotification: showSendTestNotificationConfirmation,
    actionEditPseudonymGroup: showUpdateParticipantModal,
    actionDeleteParticipant: showDeleteConfirm,
  });

  return availableActions;
};

export const useParticipantState = (props: IGetStateProps) => {
  const { therapyStart, therapyEnd } = props;
  const name = getStateName(props);
  const style = getStyle(name);
  const { t } = useTranslation();
  const description = getDescription({ state: name, therapyEnd, therapyStart, t });

  return { name, style, description };
};

export default useParticipantState;
