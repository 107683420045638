/* eslint-disable react/require-default-props */
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ITherapyElement,
  ParticipantVariableConfigsToUpdate,
  useUpdateParticipantVariableConfig,
} from "../../../../services";

import { useUserId, DummyAnchor, headerHeight } from "../../../../shared";
import { sortTherapyElementsByTitle, filterForEditedTherapyValues } from "../../../../utils";

import {
  ITherapyVariableValue,
  TherapyElementContainer,
  TherapyElementsParticipantConfig,
} from "../../components";
import { TherapyConfigForm } from "../../components/therapyConfigTable/TherapyConfigForm";

interface IParticipantTherapyConfigContentProps {
  participantId: string;
  hasPermissionToEdit: boolean;
  elementsWithEditableVariables?: ITherapyElement[];
  therapyConfig?: ITherapyVariableValue[];
}

export function ParticipantTherapyConfigContent({
  participantId,
  hasPermissionToEdit,
  elementsWithEditableVariables = [],
  therapyConfig = [],
}: IParticipantTherapyConfigContentProps) {
  const { userId } = useUserId();
  const { t } = useTranslation();
  const [forceRefresh, setForceRefresh] = useState(false);
  const { updateParticipantVariableConfig } = useUpdateParticipantVariableConfig({
    participantId,
    onError: () => setForceRefresh((prev: boolean) => !prev),
  });

  const createChildElements = useMemo(
    () =>
      sortTherapyElementsByTitle(elementsWithEditableVariables).map((item) => {
        if (typeof item === "string") {
          return <DummyAnchor key={item} id={item} />;
        }
        if (item && item.therapyVariables.length > 0) {
          return (
            <TherapyElementContainer
              id={item.id}
              key={item.id}
              title={item.title}
              description={item.description}
            >
              <TherapyElementsParticipantConfig
                therapyVariables={item.therapyVariables}
                therapyVariablesValues={extendParticipantsVariableConfigWithNewValues({
                  therapyElements: elementsWithEditableVariables,
                  participantVariableConfig: therapyConfig,
                })}
                participantId={participantId}
              />
            </TherapyElementContainer>
          );
        }

        return null;
      }),
    [elementsWithEditableVariables, therapyConfig],
  );

  const handleOnFormFinish = (editedValues: any, initialValues: any) => {
    const filteredValues = filterForEditedTherapyValues({ editedValues, initialValues });

    const preparedConfigArray: ParticipantVariableConfigsToUpdate[] = filteredValues.map(
      ({ key, currentValue }) => ({
        therapyVariableId: key,
        participantId,
        variableValue: currentValue,
        editorId: userId,
      }),
    );
    updateParticipantVariableConfig(preparedConfigArray);
  };

  const extendedTherapyConfigArray = extendParticipantsVariableConfigWithNewValues({
    therapyElements: elementsWithEditableVariables,
    participantVariableConfig: therapyConfig,
  }).map((item) => ({
    key: item.therapyVariableId,
    value: item.therapyVariableValue,
  }));

  const initialValueObject = Object.fromEntries(
    extendedTherapyConfigArray.map((e) => [e.key, e.value]),
  );

  return (
    <div style={{ maxWidth: 1000, margin: "auto" }}>
      <TherapyConfigForm
        title={t("participant.therapyConfig.title")}
        description={t("participant.therapyConfig.description")}
        editable={hasPermissionToEdit}
        affixOffsetTop={headerHeight + 16}
        initialValues={initialValueObject}
        onFinish={(values) => handleOnFormFinish(values, initialValueObject)}
        forceValueRefresh={forceRefresh}
      >
        {createChildElements}
      </TherapyConfigForm>
    </div>
  );
}

export default ParticipantTherapyConfigContent;

interface ICheckForNewEditableVariablesProps {
  therapyElements: ITherapyElement[];
  participantVariableConfig: ITherapyVariableValue[];
}

/**
 * Extends the participants variable configuration by adding new editable variables
 * that are not currently part of the stored configuration. That's the case when new
 * editable variables are added to the therapy design after the participant has been created.
 *
 */
export function extendParticipantsVariableConfigWithNewValues({
  therapyElements,
  participantVariableConfig,
}: ICheckForNewEditableVariablesProps) {
  const newEditableVariables = checkForNewEditableVariables({
    therapyElements,
    participantVariableConfig,
  });
  return participantVariableConfig.concat(
    newEditableVariables.map((item) => ({
      therapyVariableId: item.id,
      therapyVariableValue: item.defaultValue,
    })),
  );
}

/**
 * Checks if there are new editable variables in the therapy design that are not part of the
 * participants configuration yet.
 *
 */
export function checkForNewEditableVariables({
  therapyElements,
  participantVariableConfig,
}: ICheckForNewEditableVariablesProps) {
  const newVariables = therapyElements
    .filter((item) => item.therapyVariables.length > 0)
    .map((item) =>
      item.therapyVariables.filter(
        (variable) =>
          !participantVariableConfig.some((config) => config.therapyVariableId === variable.id),
      ),
    )
    .flat()
    .filter((item) => item.configurationLevel.therapistEdit);

  return newVariables;
}
